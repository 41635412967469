<template>
  <div class="page">
    <a-row class="page-content">
      <a-col :span="24">
        <a-form-model
            ref="userForm"
            :model="userForm"
            :rules="userRules"
            :label-col="{span: 4}"
            :wrapper-col="{span: 14}"
          >
            <a-form-model-item label="用户名" prop="accountName">
              <a-input
                v-model="userForm.accountName"
                :maxLength="20"
              />
            </a-form-model-item>
            <a-form-model-item label="手机号" prop="mobileNo">
              <a-input
                v-model="userForm.mobileNo"
                :maxLength="11"
              />
            </a-form-model-item>
            <a-form-model-item label="密码" prop="password">
              <a-input
                v-model="userForm.password"
                type="password"
                :maxLength="20"
              />
            </a-form-model-item>
            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
              <a-button type="primary" @click="saveUser">
                保存
              </a-button>
            </a-form-model-item>
          </a-form-model>
      </a-col>
    </a-row>

  </div>
</template>

<script>
import { addAccount } from '@/api/user';

const columns = [
  {
    title: '资方编码',
    dataIndex: 'fundCode',
    key: 'fundCode',
  },
  {
    title: '资方名称',
    dataIndex: 'fundName',
    key: 'fundName',
  },
  {
    title: '分流比例',
    dataIndex: 'section',
    key: 'section',
  },
];

export default {
  name: 'Home',
  data() {
    return {
      data: [],
      columns,
      expand: false,
      form: {
        fundCode: '',
      },
      pagination: {
        current: 1,
        total: 0,
      },
      fundEditing: false,
      userForm: {
        fundCode: '',
        fundName: '',
        section: 0,
      },
      userRules: {
        accountName: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            min: 1, max: 20, message: '用户名超出长度限制', trigger: 'blur',
          },
        ],
        mobileNo: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            min: 11, max: 11, message: '手机号位数不正确', trigger: 'blur',
          },
          {
            pattern: /^1[3456789]\d{9}$/, message: '手机号格式不正确', trigger: 'blur',
          },
        ],
        password: [
          {
            required: true, message: '请输入密码', trigger: 'blur',
          },
          {
            min: 6, max: 20, message: '密码长度不正确', trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    addFund() {
      this.fundEditing = true;
    },
    async saveUser() {
      const { success, errorMsg } = await addAccount(this.userForm);
      if (!success) {
        this.$message.error(errorMsg);
        return;
      }

      this.$message.info('保存成功');
    },
    handleSearch(e) {
      e.preventDefault();
      this.fetchPage();
    },
    async fetchPage() {
      this.loading = true;
      // const { result, success, errorMsg } = await fundListQuery({
      //   ...this.form,
      //   limit: 10,
      //   offset: this.pagination.current,
      // });
      // this.loading = false;
      // if (!success) {
      //   this.$message.error(errorMsg);
      //   return;
      // }
      // this.data = result.fundInfoList;
      // this.pagination.total = result.total;
    },
  },
  mounted() {
    // this.fetchPage();
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  background-color: #fff;
  margin-bottom: 30px;
  padding: 30px 20px;
}

.page-content {
  background-color: #fff;
  padding: 16px 12px 0;
}
</style>
