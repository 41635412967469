import axios from '@/utils/axios';

export function addAccount(data) {
  return axios({
    url: '/backend/account/addAccount',
    method: 'post',
    data,
  });
}

export function fixRepayOrderByRepayld(data) {
  return axios({
    url: '/backend/statement/fixRepayOrderByRepayId',
    method: 'post',
    data,
  });
}